
import { Controller } from '@hotwired/stimulus'

const id = '17841453486346852'
const limit = '8'
const token = 'EAANZBigoZCQF4BABHQ8aUOBwJ9uuZC36VrkXHxxpfdl0HQIBERz0nUNUhDbZARx9ZA3MZCe5U7UBB35seVEnjhZCuifIirIVhlNFdQxUW0PBUtoWUW830aq9W5ExgPdTvOWj6ZA6iTFedz1cOszZCiuMZCS1flWz3Cydty8CHMAOuDcyvlJiU9c91t21RxpcYPmtYZD'

/**
* インスタグラムの投稿を表示するためのController
*/
export class InstagramController extends Controller {
  /**
  * 投稿を取得してHTMLを生成
  */
  async connect () {
    const res = await this.fetch()
    this.createElement(res)
  }

  /**
  * @returns {object}
  */
  async fetch () {
    console.log(this.endpoint)
    const res = await fetch(this.endpoint)
    return res.json()
  }

  /**
  * @returns {string}
  */
  get endpoint () {
    return `https://graph.facebook.com/v6.0/${id}?fields=name,media.limit(${limit}){caption,media_url,thumbnail_url,permalink}&access_token=${token}`
  }

  /**
  * @param {object} res
  */
  createElement (res) {
    res.media.data.forEach((data) => {
      const linkElement = document.createElement('a')
      linkElement.href = data.permalink
      linkElement.target = 'blank'
      linkElement.classList.add('page-index__instagram-link')

      const imgElement = document.createElement('img')
      if (data.thumbnail_url) {
        console.log(data.thumbnail_url)
        imgElement.src = data.thumbnail_url
      } else {
        imgElement.src = data.media_url
      }
      imgElement.classList.add('page-index__instagram-image')
      imgElement.loading = 'lazy'

      linkElement.appendChild(imgElement)
      this.containerTarget.appendChild(linkElement)
    })
  }
}

InstagramController.targets = ['container']
