import { Controller } from '@hotwired/stimulus'
import { scrollStateInvert, scrollUnlock } from '../scroll-control'

/**
 * ハンバーガーメニューの開閉を制御する Controller
 */
export class HamburgerController extends Controller {
  /**
   * メニューをクリックした時のイベントを設定する
   */
  connect () {
    const nav = this.navTarget.querySelectorAll('.c-hamburger__list-item')
    this.navListClickReady(nav)
  }

  /**
   * ハンバーガーメニューの開閉を制御するclassを付け替える
   */
  toggle () {
    this.containerTarget.classList.toggle('c-hamburger__container--active')
    this.menuTarget.classList.toggle('c-hamburger__menu--active')
    scrollStateInvert()
  }

  /**
   * メニューを選択した時にclassを付け替えるためのイベントを準備
   *
   * @param {Array} navList
   */
  navListClickReady (navList) {
    navList.forEach((element) => {
      element.addEventListener('click', () => {
        // this.containerTarget.classList.toggle('c-hamburger__container--active')
        // this.menuTarget.classList.toggle('c-hamburger__menu--active')
        scrollUnlock()
      })
    })
  }

  /**
   * スクロールした時のクラスの付け替え
   */
  onScroll () {
    if (window.scrollY > 200) {
      this.headerTarget.classList.add('header--scroll')
    } else {
      this.headerTarget.classList.remove('header--scroll')
    }
  }
}

HamburgerController.targets = ['nav', 'menu', 'container']
