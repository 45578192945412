window.addEventListener('DOMContentLoaded', (event) => {
  const swiper = new Swiper('.page-index__mainvisual-swiper', { // eslint-disable-line
    loop: true,
    effect: 'fade',
    speed: 4000,
    allowTouchMove: false,
    autoplay: {
      delay: 0
    }
  })
})
