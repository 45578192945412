import { Application } from '@hotwired/stimulus'
import { GlobalController } from './controllers/global_controller'
import { HamburgerController } from './controllers/hamburger_controller'
import { InstagramController } from './controllers/instagram_controller'

import './scroll-control.js'
import './swiper/mainvisual.js'
import './swiper/gallery.js'

const app = Application.start()
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  app.debug = true
}

app.register('global', GlobalController)
app.register('hamburger', HamburgerController)
app.register('instagram', InstagramController)
